<template>
    <div class="PlanStepTwo">
        <div class="PlanStepTwoHeader">
            计划设置
        </div>
        <div class="PlanStepTwoForm">
            <el-form ref="form" :model="form" label-width="94px">
                <el-form-item label="计划名称">
                    <el-input v-model="form.name" style="width: 490px;" placeholder="请输入1~30个字符"></el-input>
                </el-form-item>
                <el-form-item label="计费方式">
                    <el-popover
                            placement="top-start"
                            width="200"
                            trigger="click"
                            content="CPM是指千次展现计费，依据展现次数进行收费，适合品牌曝光和活动推广。">
                        <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                    </el-popover>
                        <el-radio-group v-model="form.billing_method" style="margin-left: 20px;margin-top: -2px">
                            <el-radio :label="1">CPM</el-radio>
                        </el-radio-group>
                </el-form-item>
                <el-form-item label="每日预算">
                    <el-popover
                            placement="top-start"
                            width="200"
                            trigger="click"
                            content="不能低于当前已花费的金额。">
                        <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                    </el-popover>
                    <el-radio-group v-model="radio2" style="margin-left: 20px;margin-top: -2px" @change="selectBudget">
                        <el-radio :label="1">不限预算</el-radio>
                        <el-radio :label="2" @click.native="clickLoding">自定义预算</el-radio>
                    </el-radio-group>
                    <span v-if="form.budget" style="margin-left: 20px;color: #989898">统一日预算（￥）：{{form.budget}}</span>
                </el-form-item>
                <el-form-item label="时段设置">
                    <el-popover
                            placement="top-start"
                            width="200"
                            trigger="click"
                            content="系统只会在您设置的时间段内展示广告，其余时间不会展示。">
                        <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                    </el-popover>
                    <span style="margin-left: 20px;font-size: 18px">全日程展示广告</span>
                    <span style="margin-left: 10px;color: #1E63F1;font-size: 18px;cursor: pointer" @click="drawer2 = true">自定义</span>
                </el-form-item>
                <el-form-item label="投放策略">
                    <el-popover
                            placement="top-start"
                            width="200"
                            trigger="click"
                            content="匀速投放：时间段内的广告曝光趋于平滑和均匀；尽快投放：预算尽快消耗。">
                        <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                    </el-popover>
                    <el-radio-group v-model="delivery_speedTwo" class="radio3" style="margin-left: 20px;margin-top: -2px">
                        <el-radio :label="1">均速投放</el-radio>
                        <el-radio :label="2">尽快投放</el-radio>
                    </el-radio-group>
                    <span style="margin-left: 30px;color: #FD4446;font-size: 18px" v-if="isTitle">开启匀速投放必须设置每日预算，请填写每日预算或者关闭匀速投放功能！</span>
                </el-form-item>
            </el-form>
        </div>
        <el-drawer :wrapperClosable="false"
            size="50%"
            title="自定义预算"
            :with-header="false"
            :visible.sync="drawer"
            direction="rtl">
            <i style="position: absolute;right: 30px;top: 30px;font-size: 30px;cursor: pointer" @click="drawer = false" class="el-icon-close"></i>
        <div class="CustomBudgetBox">
            <span class="title">自定义预算</span>
            <span>所有日预算修改后，立即生效。</span>
            <span>统一日预算：从当日起，至未来所有提案数内均执行该预算。</span>
            <span>您可以编辑此次的日预算，输入的范围为：50-9999999，只能输入整数； 若您希望日预算为不限，则可置空不填，即为不限。</span>
            <span>请注意各单元的目标消耗总和不要超过计划日预算，否则会影响消耗优化的功能效果</span>
            <div class="box-bottom">
                <span>统一日预算（￥）</span>
                <el-input style="width: 380px" @input="budgetWatch" placeholder="50~9999999" v-model="form.budget"></el-input>
                <el-button style="margin-left: 20px" @click="drawer = false">确定</el-button>
            </div>
        </div>
    </el-drawer>
        <el-drawer
                :wrapperClosable="false"
                size="70%"
                title="时间设置"
                :with-header="false"
                :visible.sync="drawer2"
                direction="rtl">
            <i style="position: absolute;right: 30px;top: 30px;font-size: 30px;cursor: pointer" @click="drawer2 = false" class="el-icon-close"></i>
            <div class="CustomBudgetBox" style="height: calc(100vh - 100px); overflow-y: auto">
                <span class="title">时间设置</span>
                <span>分时折扣设置</span>
                <span>1.分时段折扣功能允许您对不同时段设置不同的折扣出价，折扣出价计算公式为：X% * 出价（X为时段折扣系数），100%即为无折扣。</span>
                <span>2.鼠标悬浮于某个时间段即可查看该时间段的折扣出价。设置折扣出价时支持鼠标点选某个时间段或者框选多个时间段。</span>
                <div class="CustomBudget-radio">
                    <el-radio-group v-model="radio4" style="margin-left: 20px;margin-top: -2px" @change="selectShowWay">
                        <el-radio :label="1">整天展示</el-radio>
                        <el-radio :label="2">自定义</el-radio>
                    </el-radio-group>
                </div>
                <div class="CustomBudget-table">
                    <div class="CustomBudget-table-header">

                    </div>
                    <el-table :data="tableData" border style="width: 100%;flex: 1;margin-top: 20px" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F8F8F8',height: '60px'}">
                        <el-table-column label="" prop="title" width="120" align="center"></el-table-column>
                        <el-table-column class-name="table-td-item" label="00:00-06:00" prop="06" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="oneToSix[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in oneToSix" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="06:00-12:00" prop="612" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="sixToTwelve[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in sixToTwelve" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="12:00-18:00" prop="1218" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="TwelveToEighteen[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in TwelveToEighteen" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="18:00-24:00" prop="1824" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="EighteenToTwentyFour[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in EighteenToTwentyFour" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span>温馨提示：默认无折扣100%，可以拖拽鼠标选择投放时间段并自定义折扣。</span>
                <div style="margin-top: 100px;display: flex;justify-content: space-between"><span></span><el-button @click="closeDrawer2" style="background: #FD4446;color: #ffffff;border-color: #FD4446;width: 120px;font-size: 20px">确定</el-button></div>
            </div>
        </el-drawer>


        <div class="nextBackBtn">
            <el-button style="width: 140px;font-size: 20px" @click="toBack">取消</el-button>
            <el-button style="background: #FD4446;border-color: #FD4446;color: #ffffff;width: 140px;font-size: 20px" @click="toNext">保存并继续</el-button>
        </div>
        <div ref="hoverWrapper" v-show="drawer2" class="hoverWrapper" :style="hoverFormData.style" v-if="hoverFormData.show">
            <p>时段{{hoverFormData.timezone}}</p>
            <p>{{hoverFormData.discount}}%折扣</p>
        </div>
        <div ref="clickWrapper" v-show="drawer2" class="clickWrapper" :style="clickFormData.style" v-if="clickFormData.show">
            <p>时段：{{clickFormData.type}}</p>
            <el-radio-group v-model="radio5">
                <el-radio :label="1">自定义</el-radio>
                <span class="customInput"><el-input oninput="value=value.replace(/[^\d]/g,'')" :disabled="radio5 === 1 ? isDisabled : !isDisabled" v-model="clickFormData.discount" style="width: 130px;" placeholder="30~500的整数"></el-input> <span>%</span></span>
                <el-radio :label="2">无折扣</el-radio>
                <el-radio :label="3">不投放</el-radio>
            </el-radio-group>
            <div class="clickWrapperBtn">
                <el-button @click="clickFormData.show = false">取消</el-button>
                <el-button @click="setTime(clickFormData.name,clickFormData.index,clickFormData.discount)" style="background-color: #FD4446;border-color: #FD4446;color: #ffffff">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlanStepTwo",
        data(){
            return {
                delivery_speedTwo:1,
                form:{
                    name:null,
                    billing_method:1, //计费方式
                    budget:null, //预算
                    delivery_speed:1 //投放类型
                },
                radio:1,
                radio2:1,
                radio4:1,
                radio5:0,
                drawer:false,
                drawer2:false,
                isTitle:false,
                tableData:[{
                    title:'时段',
                    '06':'1',
                    '612':'',
                    '1218':'',
                    '1824':'',
                }],
                oneToSix: [
                    {"type":0,"discount":100,"launch":1,'timezone':'00:00-01:00','name':'oneToSix','index':0},
                    {"type":1,"discount":100,"launch":1,'timezone':'01:00-02:00','name':'oneToSix','index':1},
                    {"type":2,"discount":100,"launch":1,'timezone':'02:00-03:00','name':'oneToSix','index':2},
                    {"type":3,"discount":100,"launch":1,'timezone':'03:00-04:00','name':'oneToSix','index':3},
                    {"type":4,"discount":100,"launch":1,'timezone':'04:00-05:00','name':'oneToSix','index':4},
                    {"type":5,"discount":100,"launch":1,'timezone':'05:00-06:00','name':'oneToSix','index':5},
                ],
                sixToTwelve: [
                    {"type":6,"discount":100,"launch":1,'timezone':'06:00-07:00','name':'sixToTwelve','index':0},
                    {"type":7,"discount":100,"launch":1,'timezone':'07:00-08:00','name':'sixToTwelve','index':1},
                    {"type":8,"discount":100,"launch":1,'timezone':'08:00-09:00','name':'sixToTwelve','index':2},
                    {"type":9,"discount":100,"launch":1,'timezone':'09:00-10:00','name':'sixToTwelve','index':3},
                    {"type":10,"discount":100,"launch":1,'timezone':'10:00-11:00','name':'sixToTwelve','index':4},
                    {"type":11,"discount":100,"launch":1,'timezone':'11:00-12:00','name':'sixToTwelve','index':5},
                ],
                TwelveToEighteen: [
                    {"type":12,"discount":100,"launch":1,'timezone':'12:00-13:00','name':'TwelveToEighteen','index':0},
                    {"type":13,"discount":100,"launch":1,'timezone':'13:00-14:00','name':'TwelveToEighteen','index':1},
                    {"type":14,"discount":100,"launch":1,'timezone':'14:00-15:00','name':'TwelveToEighteen','index':2},
                    {"type":15,"discount":100,"launch":1,'timezone':'15:00-16:00','name':'TwelveToEighteen','index':3},
                    {"type":16,"discount":100,"launch":1,'timezone':'16:00-17:00','name':'TwelveToEighteen','index':4},
                    {"type":17,"discount":100,"launch":1,'timezone':'17:00-18:00','name':'TwelveToEighteen','index':5},
                ],
                EighteenToTwentyFour: [
                    {"type":18,"discount":100,"launch":1,'timezone':'18:00-19:00','name':'EighteenToTwentyFour','index':0},
                    {"type":19,"discount":100,"launch":1,'timezone':'19:00-20:00','name':'EighteenToTwentyFour','index':1},
                    {"type":20,"discount":100,"launch":1,'timezone':'20:00-21:00','name':'EighteenToTwentyFour','index':2},
                    {"type":21,"discount":100,"launch":1,'timezone':'21:00-22:00','name':'EighteenToTwentyFour','index':3},
                    {"type":22,"discount":100,"launch":1,'timezone':'22:00-23:00','name':'EighteenToTwentyFour','index':4},
                    {"type":23,"discount":100,"launch":1,'timezone':'23:00-24:00','name':'EighteenToTwentyFour','index':5}
                ],
                visible:false,
                hoverFormData: {
                    show: false,
                    type: null,
                    discount: null,
                    launch:null,
                    timezone:null,
                    name:null,
                    index:null,
                    style: ''
                },
                clickFormData: {
                    show: false,
                    discount:null,
                    type: null,
                    launch:null,
                    timezone:null,
                    name:null,
                    index:null,
                    style: ''
                },
                isDisabled:false
            }
        },
        created() {
            this.selectBudget();
        },
        mounted() {
            this.editData();
        },
        methods: {
            budgetWatch(){
                if(!Number(this.form.budget)){
                    this.form.budget = ''
                } else {
                    this.form.budget = parseInt(this.form.budget)
                }
            },
            editData(){
                // console.log(265,this.$route.query)
                if(this.$route.query.editId){
                    this.$http.axiosGetBy(this.$api.storeDrainage, {id:this.$route.query.editId}, res=>{
                        if(res.code === 200){
                            let resData = res.data;
                            this.form.name = resData.name;
                            this.form.billing_method = resData.billing_method;
                            this.form.budget = resData.budget;
                            this.$set(this.form,'delivery_speed',resData.delivery_speed)
                            this.delivery_speedTwo = resData.delivery_speed
                            // this.form.delivery_speed = resData.delivery_speed;
                            if(resData.budget != 0){
                                this.radio2 = 2;
                                this.form.delivery_speed = 1;
                            } else {
                                this.radio2 = 1;
                                this.form.delivery_speed = 2
                            }
                            resData.time_interval.map(item=>{
                                if(item.launch === 1 && item.discount === 100){
                                    this.radio4 = 1
                                } else {
                                    this.radio4 = 2
                                }
                            })
                            let list = [];
                            this.oneToSix =  this.forTimeInterval(list,resData.time_interval,0,6);
                            this.sixToTwelve =  this.forTimeInterval(list,resData.time_interval,6,12);
                            this.TwelveToEighteen =  this.forTimeInterval(list,resData.time_interval,12,18);
                            this.EighteenToTwentyFour =  this.forTimeInterval(list,resData.time_interval,18,24);

                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            forTimeInterval(arr,arrTotal,minIndex,maxIndex){
                arr=[];
              for(let i = minIndex; i < maxIndex; i++){
                  arr.push(arrTotal[i])
              }
              return arr;
            },
            setTime(name,index,discount){
                if(name == 'oneToSix'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.oneToSix[index].discount = discount;
                        this.oneToSix[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.oneToSix[index].launch = 1
                        this.oneToSix[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.oneToSix[index].launch = 0;
                        this.oneToSix[index].discount = 0;
                    }
                } else if(name == 'sixToTwelve'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.sixToTwelve[index].discount = discount;
                        this.sixToTwelve[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.sixToTwelve[index].launch = 1
                        this.sixToTwelve[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.sixToTwelve[index].launch = 0;
                        this.sixToTwelve[index].discount = 0;
                    }
                } else if(name == 'TwelveToEighteen'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.TwelveToEighteen[index].discount = discount;
                        this.TwelveToEighteen[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.TwelveToEighteen[index].launch = 1
                        this.TwelveToEighteen[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.TwelveToEighteen[index].launch = 0;
                        this.TwelveToEighteen[index].discount = 0;
                    }
                } else if(name == 'EighteenToTwentyFour'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.EighteenToTwentyFour[index].discount = discount;
                        this.EighteenToTwentyFour[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.EighteenToTwentyFour[index].launch = 1
                        this.EighteenToTwentyFour[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.EighteenToTwentyFour[index].launch = 0;
                        this.EighteenToTwentyFour[index].discount = 0;
                    }
                }
                let num1 = 0;
                let num2 = 0;
                let num3 = 0;
                let num4 = 0;
                for(let i = 0; i < this.oneToSix.length; i++){
                    if(this.oneToSix[i].discount === 0){
                        this.radio4 = 2
                    } else {
                        num1++
                    }
                }
                for(let i = 0; i < this.sixToTwelve.length; i++){
                    if(this.sixToTwelve[i].discount === 0){
                        this.radio4 = 2
                    } else {
                        num2++
                    }
                }
                for(let i = 0; i < this.TwelveToEighteen.length; i++){
                    if(this.TwelveToEighteen[i].discount === 0){
                        this.radio4 = 2
                    } else {
                        num3++
                    }
                }
                for(let i = 0; i < this.EighteenToTwentyFour.length; i++){
                    if(this.EighteenToTwentyFour[i].discount === 0){
                        this.radio4 = 2
                    } else {
                        num4++
                    }
                }
                if(num1 === 6 && num2 === 6 && num3 === 6 && num4 === 6){
                    this.radio4 = 1
                }
                this.clickFormData.show = false;
            },
            selectBudget(val){
                this.radio2 = val;
                if(this.radio2 == 2){
                    this.drawer = true
                } else if(this.radio2 = 1){
                    this.form.budget = 0;
                }
            },
            clickLoding(){
                this.drawer = true
            },
            closeDrawer2(){
                this.clickFormData.show = false;
                this.drawer2 = false;
            },
            toBack(){
                this.$router.go(-1)
            },
            clickDialog(item, evt) {
                if(item.launch === 0){
                    this.radio5 = 3
                } else {
                    if(item.discount != 100){
                        this.radio5 = 1
                    } else {
                        this.radio5 = 2;
                    }
                }
                let winClientWidth = document.body.clientWidth;
                this.closeHoverD();
                this.clickFormData.timezone = item.timezone;
                this.clickFormData.name = item.name;
                this.clickFormData.index = item.index;
                this.clickFormData.launch = item.launch;
                this.clickFormData.discount = item.discount;
                this.clickFormData.show = true;
                let x = evt.clientX + 20;
                let y = evt.clientY + 20;
                if(winClientWidth - 246 < x){
                    this.clickFormData.style = {
                        left: `${winClientWidth - 246}px`,
                        top: `${y}px`
                    }
                }
                 else {
                    this.clickFormData.style = {
                        left: `${x}px`,
                        top: `${y}px`
                    }
                }
            },
            hoverDialog(item, evt) {
                let winClientWidth = document.body.clientWidth;
                this.hoverFormData.timezone = item.timezone;
                this.hoverFormData.discount = item.discount;
                this.hoverFormData.show = true;
                let x = evt.clientX + 4;
                let y = evt.clientY - 100;
                if(winClientWidth - 210 < x){
                    this.hoverFormData.style = {
                        left: `${winClientWidth - 210 }px`,
                        top: `${y}px`
                    }
                } else {
                    this.hoverFormData.style = {
                        left: `${x}px`,
                        top: `${y}px`
                    }
                }
            },
            closeHoverD() {
                this.hoverFormData.show = false;
            },
            selectShowWay(val){
                this.radio4 = val
                this.clickFormData.show = false;
                if(this.radio4 === 1){
                    this.oneToSix = this.forTimeSet2(this.oneToSix);
                    this.sixToTwelve = this.forTimeSet2(this.sixToTwelve);
                    this.TwelveToEighteen = this.forTimeSet2(this.TwelveToEighteen);
                    this.EighteenToTwentyFour = this.forTimeSet2(this.EighteenToTwentyFour);
                } else if(this.radio4 === 2){
                    this.oneToSix = this.forTimeSet(this.oneToSix);
                    this.sixToTwelve = this.forTimeSet(this.sixToTwelve);
                    this.TwelveToEighteen = this.forTimeSet(this.TwelveToEighteen);
                    this.EighteenToTwentyFour = this.forTimeSet(this.EighteenToTwentyFour);
                }
            },
            forTimeSet(arr){
                for(let i = 0; i < arr.length; i++){
                    arr[i].launch = 0;
                    arr[i].discount = 0;
                }
                return arr;
            },
            forTimeSet2(arr){
                for(let i = 0; i < arr.length; i++){
                    arr[i].launch = 1;
                    arr[i].discount = 100;
                }
                return arr;
            },
            toNext(){
                this.form.delivery_speed = this.delivery_speedTwo;
                if(this.radio2 == 1 && this.form.delivery_speed == 1){
                    this.isTitle = true;
                } else {
                    if(!this.form.name){
                        this.$message.warning('请填写计划名称！')
                        return
                    } else if(this.radio2 === 2){
                        if(this.form.budget < 50 || this.form.budget > 9999999){
                            this.$message.warning('自定义预算范围为50~9999999');
                            return;
                        }
                    }
                    this.isTitle = false;
                    let budget = [];
                    this.oneToSix.map(item=>{
                        budget.push(item)
                    })
                    this.sixToTwelve.map(item=>{
                        budget.push(item)
                    })
                    this.TwelveToEighteen.map(item=>{
                        budget.push(item)
                    })
                    this.EighteenToTwentyFour.map(item=>{
                        budget.push(item)
                    })
                    let list = [];
                    budget.map(item=>{
                        if(item.launch === 1){
                            list.push(item)
                        }
                    })
                    if(list.length === 0){
                        this.$message.warning('时段设置至少设置一个！');
                        return;
                    }
                    this.$lockr.set('onePlanTwo',{name:this.form.name,billing_method:this.form.billing_method,budget:this.form.budget,time_interval:budget,delivery_speed:this.form.delivery_speed})
                    // return
                    if(this.$route.query.editId){
                        let id = this.$route.query.editId;
                        this.$router.push({
                            path:'/student/operationpromotion/boothadvertisingthree',
                            query:{
                                editId:id
                            }
                        })
                    } else {
                        this.$router.push({
                            path:'/student/operationpromotion/boothadvertisingthree',
                        })
                    }

                }
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .isShowActive{
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    /deep/.el-radio .el-radio__label{
        color: #1E63F1;
        font-size: 18px;
    }
    /deep/.el-form-item__label{
        color: #333333;
        font-size: 18px;
        font-width: 500;
    }
    /deep/.radio3{
        .el-radio__label{
            color: #333333;
        }
    }
    .PlanStepTwo{
        height: calc(100vh - 200px);
        padding: 20px 30px;
        .PlanStepTwoHeader{
            color: #333333;
            font-size: 28px;
        }
        .PlanStepTwoForm{
            margin-top: 70px;
            padding-left: 40px;
        }
        .nextBackBtn{
            padding-left: 136px;
            padding-top: 20px;
        }
    }
    .CustomBudgetBox{
        display: flex;
        flex-direction: column;
        padding: 40px 60px;
        span{
            color: #989898;
            font-size: 18px;
            line-height: 1;
            margin-top: 20px;
        }
        .title{
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        span:nth-child(4){
            line-height: 27px;
        }
        .box-bottom{
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
        }
    }
    .CustomBudget-radio{
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    .hoverWrapper {
        padding: 10px 10px 10px 20px;
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 210px;
        /*height: 200px;*/
        background-color: #FFF;
        border: 1px solid #EEE;
        font-size: 18px;
        p{
            line-height: 27px;
        }
    }
    .clickWrapper{
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 246px;
        padding-top: 20px;
        background-color: #FFF;
        border: 1px solid #EEE;
        padding-left: 20px;
        font-size: 18px;
        padding-bottom: 30px;
        /deep/.el-radio{
            margin-top: 20px;
        }
        /deep/.el-radio-group{
            display: flex;
            flex-direction: column;
        }
        /deep/.el-radio__label{
            color: #333333!important;
            font-size: 18px;
        }
        .customInput{
            margin-top: 10px;
            display: flex;
            margin-left: 30px;
            padding-right: 34px;
            align-items: center;
            span{
                font-size: 18px;
            }
        }
        .clickWrapperBtn{
            margin-top: 20px;
            padding-left: 44px;
        }
    }


</style>